import { useState, useEffect } from "react";
import "./App.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";

function App() {
  const [message, setMessage] = useState("");
  const [msgParam, setMsgParam] = useState("");

  const [isDecoding, setIsDecoding] = useState(false);
  const [decodedMessage, setDecodedMessage] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const msg = searchParams.get("msg");
    if (msg) {
      setMessage(msg);
      decodeMessage(msg);
    }
  }, []);

  const updateUrl = (msg) => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("msg", msg);
    window.history.pushState(null, "", newUrl.toString());
  };

  // function to decode message that accepts a msg string and isHex boolean
  const decodeMessage = (msg, isHex) => {
    const queryParams = new URLSearchParams({
      msg: msg,
      is_hex: isHex ? "true" : "false",
    });
    const url = `https://api.ocn.ai/seamores/sensor-readings?${queryParams.toString()}`;
    console.log(url);

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        // if data has key error, then there was an error decoding the message
        if (data.error) {
          console.error("Error:", data.error);
          setError(data.error);
          setDecodedMessage();
        } else {
          setDecodedMessage(data);
          updateUrl(msg);
        }
        setIsDecoding(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsDecoding(false);
      });
  };

  const decode = (e) => {
    if (decodedMessage) {
      setDecodedMessage();
      setMessage("");
      setMsgParam("");
    } else {
      e.preventDefault();
      setIsDecoding(true);
      setError();
      const isHex = e.target[1].checked;
      console.log(message, isHex);
      decodeMessage(message, isHex);
    }
  };

  return (
    <>
      <Container>
        <Row className="py-lg-5">
          <div className="col-lg-6 col-md-8 mx-auto">
            <h1>Seamore Decoder Ring</h1>
            <Form onSubmit={decode}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Enter message"
                  disabled={decodedMessage}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="HEX"
                  disabled={decodedMessage}
                />
              </Form.Group>
              {decodedMessage ? (
                <Button variant="warning" type="submit">
                  Reset
                </Button>
              ) : (
                <Button variant="primary" type="submit" disabled={isDecoding}>
                  {isDecoding ? "Decoding…" : "Decode"}
                </Button>
              )}
            </Form>
          </div>
        </Row>
        <Row className="">
          <div className="col-lg-6 col-md-8 mx-auto">
            {error && <Alert variant="danger">Error: {error}</Alert>}
            {decodedMessage && (
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Sensor</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>message_id</td>
                    <td>{decodedMessage.message_id}</td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>unit_id</td>
                    <td>{decodedMessage.unit_id}</td>
                  </tr>
                  <tr>
                    <td>03</td>
                    <td>error_flags</td>
                    <td>{decodedMessage.error_flags}</td>
                  </tr>
                  <tr>
                    <td>04</td>
                    <td>unit_status</td>
                    <td>{decodedMessage.unit_status}</td>
                  </tr>
                  <tr>
                    <td>05</td>
                    <td>reserved</td>
                    <td>{decodedMessage.reserved}</td>
                  </tr>
                  <tr>
                    <td>06</td>
                    <td>reserved_2</td>
                    <td>{decodedMessage.reserved_2}</td>
                  </tr>
                  <tr>
                    <td>07</td>
                    <td>timestamp</td>
                    <td>{decodedMessage.timestamp}</td>
                  </tr>
                  <tr>
                    <td>08</td>
                    <td>lat</td>
                    <td>{decodedMessage.lat}</td>
                  </tr>
                  <tr>
                    <td>09</td>
                    <td>lon</td>
                    <td>{decodedMessage.lon}</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>wave_height</td>
                    <td>{decodedMessage.wave_height}</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>signal_to_noise</td>
                    <td>{decodedMessage.signal_to_noise}</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>messages_in_queue</td>
                    <td>{decodedMessage.messages_in_queue}</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>current</td>
                    <td>{decodedMessage.current}</td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>battery_voltage</td>
                    <td>{decodedMessage.battery_voltage}</td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>ambient_light</td>
                    <td>{decodedMessage.ambient_light}</td>
                  </tr>
                  <tr>
                    <td>16</td>
                    <td>temperature</td>
                    <td>{decodedMessage.temperature}</td>
                  </tr>
                  <tr>
                    <td>17</td>
                    <td>ph</td>
                    <td>{decodedMessage.ph}</td>
                  </tr>
                  <tr>
                    <td>18</td>
                    <td>orp</td>
                    <td>{decodedMessage.orp}</td>
                  </tr>
                  <tr>
                    <td>19</td>
                    <td>turbidity</td>
                    <td>{decodedMessage.turbidity}</td>
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>transmissivity</td>
                    <td>{decodedMessage.transmissivity}</td>
                  </tr>
                  <tr>
                    <td>21</td>
                    <td>dissolved_oxygen_concentration</td>
                    <td>{decodedMessage.dissolved_oxygen_concentration}</td>
                  </tr>
                  <tr>
                    <td>22</td>
                    <td>conductivity</td>
                    <td>{decodedMessage.conductivity}</td>
                  </tr>
                  <tr>
                    <td>23</td>
                    <td>depth</td>
                    <td>{decodedMessage.depth}</td>
                  </tr>
                  <tr>
                    <td>24</td>
                    <td>barometric_pressure</td>
                    <td>{decodedMessage.barometric_pressure}</td>
                  </tr>
                  <tr>
                    <td>25</td>
                    <td>total_dissolved_gas</td>
                    <td>{decodedMessage.total_dissolved_gas}</td>
                  </tr>
                  <tr>
                    <td>26</td>
                    <td>chlorophyll_a_blue</td>
                    <td>{decodedMessage.chlorophyll_a_blue}</td>
                  </tr>
                  <tr>
                    <td>27</td>
                    <td>chlorophyll_a_red</td>
                    <td>{decodedMessage.chlorophyll_a_red}</td>
                  </tr>
                  <tr>
                    <td>28</td>
                    <td>rhodamine_dye</td>
                    <td>{decodedMessage.rhodamine_dye}</td>
                  </tr>
                  <tr>
                    <td>29</td>
                    <td>phycocyanin</td>
                    <td>{decodedMessage.phycocyanin}</td>
                  </tr>
                  <tr>
                    <td>30</td>
                    <td>cdom_fdom</td>
                    <td>{decodedMessage.cdom_fdom}</td>
                  </tr>
                  <tr>
                    <td>31</td>
                    <td>optical_brightener</td>
                    <td>{decodedMessage.optical_brightener}</td>
                  </tr>
                  <tr>
                    <td>32</td>
                    <td>tryptophan</td>
                    <td>{decodedMessage.tryptophan}</td>
                  </tr>
                  <tr>
                    <td>33</td>
                    <td>fluorescein_dye</td>
                    <td>{decodedMessage.fluorescein_dye}</td>
                  </tr>
                  <tr>
                    <td>34</td>
                    <td>ptsa</td>
                    <td>{decodedMessage.ptsa}</td>
                  </tr>
                  <tr>
                    <td>35</td>
                    <td>refined_oil</td>
                    <td>{decodedMessage.refined_oil}</td>
                  </tr>
                  <tr>
                    <td>36</td>
                    <td>crude_oil</td>
                    <td>{decodedMessage.crude_oil}</td>
                  </tr>
                  <tr>
                    <td>37</td>
                    <td>ammonium</td>
                    <td>{decodedMessage.ammonium}</td>
                  </tr>
                  <tr>
                    <td>38</td>
                    <td>nitrate</td>
                    <td>{decodedMessage.nitrate}</td>
                  </tr>
                  <tr>
                    <td>39</td>
                    <td>chloride</td>
                    <td>{decodedMessage.chloride}</td>
                  </tr>
                  <tr>
                    <td>40</td>
                    <td>sodium</td>
                    <td>{decodedMessage.sodium}</td>
                  </tr>
                  <tr>
                    <td>41</td>
                    <td>calcium</td>
                    <td>{decodedMessage.calcium}</td>
                  </tr>
                  <tr>
                    <td>42</td>
                    <td>bromide</td>
                    <td>{decodedMessage.bromide}</td>
                  </tr>
                  <tr>
                    <td>43</td>
                    <td>photometric_par</td>
                    <td>{decodedMessage.photometric_par}</td>
                  </tr>
                  <tr>
                    <td>44</td>
                    <td>carbon_dioxide</td>
                    <td>{decodedMessage.carbon_dioxide}</td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        </Row>
      </Container>
    </>
  );
}

export default App;
